import React from "react";
import { TiSocialFacebookCircular, TiSocialLinkedinCircular, TiSocialInstagram} from "react-icons/ti"
import { SiGmail } from "react-icons/si";
import "../style/SectionCinco.css"
import { useState, useEffect } from "react";

function SectionCinco(props) {
    
    const images = [props.imgAbsolute1, props.imgAbsolute2, props.imgAbsolute3];

    const [imageP, setImageP] = useState(0);

    useEffect(()=>{
        const intervalo = setInterval(() => {
            setImageP(prevIndex=>
                prevIndex === images.length -1 ? 0 : prevIndex + 1
            );
        },3000);

        return ()=>clearInterval(intervalo);
    },[images.length]);

    return(
        <footer className="section__cinco" style={{backgroundColor: props.fondoColor}}>

            <div className="footer__divAbsolute_3">
                <div className="footer__divAbsoluteDiv_1">
                    <div className="footer__divAbsoluteDiv_2">
                        {images.map((image,index)=>(
                            <img 
                            key={index}
                            src={image}
                            alt={`imagen-${index}`}
                            className= { 
                                index === imageP ? 'slide-in' : 'slide-out'
                            }
                            />
                        ))}
                    </div>
                </div>
            </div>

            <div className="footer__divS footer__div__1">
                {/* asiganarle estilos y clases a los elementos*/}

                
                <div className="footerDiv1">
                    <div className="footerDiv1__div1">
                        <img src={props.img1} alt={props.alt1} />
                    </div>
                    {/* SEGUIR ESTILIZANDO */}
                    <div className="footerDiv1__div2">
                        <div className="footerDiv1__div2DivH3">
                            <h3 style={{color: props.colorLetra}}>{props.h3}</h3>
                        </div>

                        <div className="footerDiv1__div2DivP">
                            <p style={{color: props.colorLetra}}>{props.p1}</p>
                        </div>

                        <div className="footerDiv1__div2DivIcons" style={{justifyContent:props.justifyContentIconos}}>
                            {/* <a href={props.facebookLink}><TiSocialFacebookCircular 
                            className="TiSocial__footer"
                            /></a> */}
                            <a href={props.instagramLink}><TiSocialInstagram
                            className="TiSocial__footer" 
                            /></a>
                            {/* <a href={props.linkedinLink}><TiSocialLinkedinCircular
                            className="TiSocial__footer"
                            /></a> */}
                            <a href={props.gmailLink}><SiGmail className="TiSocial__footer">
                                </SiGmail></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer__divS footer__div__2">
                
            </div>

            
        </footer>
    );
}

export default SectionCinco;