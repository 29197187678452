
import '../style/Nav.css';
import { FiMenu } from "react-icons/fi";
import React, { useState } from "react";
import { Link } from 'react-router-dom';

export function Nav (props){
    const [visible, setVisible] = useState(false);
    
    const estadoVisible = ()=>{
        setVisible(!visible);
    }

    const handleLinkClick = (clickHandler) => {
        setVisible(false);
        if (clickHandler) clickHandler();
    }

    
    return(

        <div className="nav" style={{backgroundColor: props.colorFondo}}>
            <div className="menu-responsive">
                <div className="responsive-btn">
                    <FiMenu className="btn-r" onClick={estadoVisible}/>
                </div>
            </div>

            <div className="nav-titulo">
                <h1>{props.titulo}</h1>
            </div>
            
            <div className="nav-a">
                <Link to={props.enlace1}
                onClick={props.clickUno}
                >{props.a1}</Link>
                <Link to={props.enlace2}
                onClick={props.clickDos}
                >
                    {props.a2}</Link>
                <Link to={props.enlace3}
                onClick={props.clickTres}
                >{props.a3}</Link>
            </div>

            <ResponsiveMain
                colorFondo = {props.colorFondo}
                enlace1={props.enlace1}
                enlace2={props.enlace2}
                enlace3={props.enlace3}
                a1={props.a1} clickUno={() => handleLinkClick(props.clickUno)}
                a2={props.a2} clickDos = {() => handleLinkClick(props.clickDos)}
                a3={props.a3} clickTres = {() => handleLinkClick(props.clickTres)}
                imgResponsive = {props.imgResponsive}
                visible={visible}

            />
        </div>
    );
}

export function ResponsiveMain (props){
    const {visible}=props;
    const styleVisible={display : visible? "flex" : "none",
                            backgroundColor:props.colorFondo,
                            transition:'all 2s'
    }
    
    return(
        <div className="responsive" style={styleVisible} >
            <div className="responsive_div-f">
                <a href={props.enlace1}
                onClick={props.clickUno}>
                    {props.a1}</a>
                <a href={props.enlace2}
                onClick={props.clickDos}>
                    {props.a2}</a>
                <a href={props.enlace3}
                onClick={props.clickTres}>
                    {props.a3}</a>
            </div>
            <div className="responsive_div_s">
                <img src={props.imgResponsive} alt={props.imgResponsive} />
            </div>
        </div>
    );
}
export function Com() {
    return(
        <div></div>
    );
}
export default Com;