import React, {useState, useEffect} from "react";
import Typewriter from 'typewriter-effect'
import '../style/Section.css'


export function Section(props){
    
    // const [imagenOculta, imagenVisible] = useState(false);

    // useEffect(()=>{
    //     const tiempo = setTimeout(()=>{
    //         imagenVisible(true)
    //     },1000);

    //     return ()=>{
    //         clearTimeout(tiempo);
    //     };
    // },[]);

    
    return(
        <section className="section-principal">
            <div className="section_absolute">
                <img className="img__absolute" src={props.imgH} alt={props.imgAltH} style={{filter:props.filtroImgH}}/>
                
            </div>

            <div className="section_relative">
                <div className="div_relative-p">
                    <div className="div_relative-content">
                        <h1 style={{color: props.colorLetra,
                                    filter: props.filtroLetra,
                                    animation: props.tituloAnimacion
                        }}>{props.titulo}
                            <h2 className="tituloDos__deTituloUno">
                                {props.tituloDos}
                            </h2>
                        </h1>
                        <p>{props.descripcion}</p>
                        <button onClick={props.click}>{props.boton }</button>
                    </div>
                    <div>
                        
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Section;