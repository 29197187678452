import './App.css';
import React, {useState, useCallback, useRef} from 'react';
import Comp, {Nav, ResponsiveMain} from "./components/Nav";
import InicioUno from './components/inicio/inicioUno/InicioUno';

import ImagenInicioFondo from './img/tecnologia-argentina.webp'
import CrecimientoImagen from './img/crecimiento_digita_economico.png'
import PersuacionImagen from './img/influencia-social-scaled.webp'
import WebDinamicaImagen from './img/web_dinamica.webp'
import ProfesionalImagen from './img/profesional-exito.webp'
import LandingImagen from './img/landing.webp'
import ProgramadorImagen from './img/programador.jpg'
import LogoWeb from './img/logo_web_dinamica.webp'

import SectionDos from './components/SectionDos';
import Items,{ComponenteItem, ItemUno } from './components/componenteItem/ComponenteItem';
import Contacto from './components/SectionCinco'
import Footer from './components/footerUno/FooterUno'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function Inicio() {
    const sectionRefUno = useRef(null);
    const sectionRefDos = useRef(null);
    const sectionRefTres = useRef(null);

    const scrollSections = (ref)=>()=>{
    if (ref.current) {
        ref.current.scrollIntoView({behavior:'smooth'});
    }
} 

  return (
    <div className="inicio">

      <Nav
        titulo='Empsites'
        a1='Inicio' 
        clickUno= {scrollSections(sectionRefUno)}
        a2='Nosotros' enlace2 = './paginados'
        // clickDos={scrollSections(sectionRefDos)}
        a3='Contacto' 
        clickTres= {scrollSections(sectionRefTres)}
        colorFondo='rgb(116 77 249)'
        
      />
      
      <section ref={sectionRefUno}>
      <InicioUno
      ImagenFondo = {ImagenInicioFondo} AltImagenFondo= 'Desarrollo web imagen'
      Titulo = 'Digitalizate con Nosotros'
      Descripcion = 'Desarrollamos tu Sitio para que sigas Creciendo'
      Enlace = 'Contactanos' clickUno = {scrollSections(sectionRefTres)}
      ColorLetra = '#fff'
      ColorAbsoluteItem = 'rgb(116 77 249)'

      
      />
      </section>

    {/* <SectionDos
      titulo='Tu sitio con nosotros'
      parrafo='Nos encargamos de hacer Sitios Webs que se ajusten a ti!'
      // fondoColor='rgb(116 77 249)'
      colorLetra = 'black'
      buttonOpen={false}
      // filtro = "drop-shadow"
      /> */}

      
      <SectionDos
        titulo='Digitalizate'
        parrafo='Tener precencia Online permitira que generes un crecimiento
        de alto Impacto en tu area.'
        fondoColor = "#fff"
        imgC = {CrecimientoImagen} imgAltC='Crecimiento digital web'
        div = {true}
        colorLetra = "black"
        // buttonOpen = {true} boton= 'Ver mas' link = 'https://www.youtube.com'
        // animacion = "rotar infinite 20s linear"
        filter = "none"
      />
      

      <SectionDos
      titulo = 'Que beneficios hay en tener tu Sitio?'
      parrafo = 'Los mayores beneficios de tener tu Sitio es que puedes mostrar a lo que te dedicas para persuadir de forma eficas a tus futuros clientes.'
      fondoColor='#fff'
      imgC={PersuacionImagen} imgAltC='persuacion web imagen'
      div = {true}
      colorLetra = "black"
      divPosition = {2}
      />
      <SectionDos
      titulo='Nosotros lo creamos por ti!'
      parrafo='Asi no tienes que hacerte problemas'
      fondoColor='rgb(116 77 249)'
      colorLetra = '#fff'
      buttonOpen={false}
      filtro = "drop-shadow"
      />

      <section ref={sectionRefDos}>
      <SectionDos
      titulo = 'De forma muy efectiva'
      parrafo = 'Crearemos tu sitio para ti, ya sea una pagina para tu empresa, institucion o para que ofrescas tus servicios como profecional.'
      fondoColor='#fff'
      imgC={ProgramadorImagen} imgAltC='programador web full stack'
      div = {true}
      colorLetra = "black"
      divPosition = {1}
      />
      </section>

      <ComponenteItem
      tituloItemA = 'Landing Page'
      imagenItemUnoA = {LandingImagen} altItemUnoA='imagen de landing page'
      parrafoItemA = 'Paginas de bajo costo para Arrancar'
      enlaceItemTextoA = 'Contactar'
      // enlaceItemA= '#'
      clickUno = {scrollSections(sectionRefTres)}

      tituloItemB = 'Web Dinamica'
      imagenItemUnoB = {WebDinamicaImagen} altItemUnoB='imagen de web dinamica'
      parrafoItemB = 'Paginas funcionales para Mas Abanzados'
      enlaceItemTextoB = 'Contactar'
      // enlaceItemB= '#'
      clickDos = {scrollSections(sectionRefTres)}

      tituloItemC = 'Portfolios'
      imagenItemUnoC = {ProfesionalImagen} altItemUnoC='web para trabajador profecional'
      parrafoItemC = 'Portfolios para trabajadores Profesionales'
      enlaceItemTextoC = 'Contactar'
      // enlaceItemC= '#'
      clickTres = {scrollSections(sectionRefTres)}

      colorBorde = 'rgb(116 77 249)'
      colorFondoItem = 'rgb(59 0 255)'
      colorLetraEnlace = '#fff'
      />

      <section ref={sectionRefTres}>
      <Contacto
      h3= 'Contactanos!'
      p1 = 'Si quieres que realizemos tu Sitio puedes contactarnos por estos medios'
      colorLetra= '#fff'
      fondoColor = 'rgb(116 77 249)'
      img1={LogoWeb} alt1='logo de empresa de desarrollo web'
      justifyContentIconos = 'space-evenly'
      instagramLink= 'https://www.instagram.com/empsitesweb/'
      gmailLink='mailto:empsitesweb@gmail.com'
      />
      </section>
      <Footer
      copyright = 'Copyright © 2024 todos los derechos EmpSites'
      liUno='Inicio |' clickUno = {scrollSections(sectionRefUno)}
      liDos='Contacto |' clickDos = {scrollSections(sectionRefTres)}
      liTres='Nosotros' clickTres = {scrollSections(sectionRefDos)}
      />

    </div>
  );
}

export default Inicio;