import './FooterUno.css'

function FooterUno(props) {
    
    return(
        <footer className='footerUno'>
            <div className='footerUno__absoluteDiv'></div>
            <div className='footerUno__relativeDiv'>
                <div className='footerUno__relativeDiv_DivUno'>
                    <div>
                        <h4>{props.copyright}</h4>
                        <ul>
                            <li onClick={props.clickUno}>{props.liUno}</li>
                            <li onClick={props.clickDos}>{props.liDos}</li>
                            <li onClick={props.clickTres}>{props.liTres}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterUno;