import  React,{useRef,useState,useEffect}  from "react";
import Comp, {Nav, ResponsiveMain} from "./components/Nav";
import Section from './components/Section'
import SectionDos from './components/SectionDos'
import Contacto from './components/SectionCinco'

import ReactImg from './img/reactImg.webp'
import EmpresaImg from './img/empresa.webp'
import ProgramadorImg from './img/Programadores-de-Software.webp'
import NodeImg from './img/nodeImg.webp.png'
import LogoWeb from './img/logo_web_dinamica.webp'
// import './style/PaginaDos.css'

function PaginaDos(props) {

    // const funcionScroll = ()=>{
    //     const [visible, setVisible]=useState(false);
    //     const elementoRef = useRef(null);

    //     const activacionScroll = () =>{
    //         if (elementoRef.current) {
    //             const rect = elementoRef.current.getBoundingClientRect();
    //             const ventanaAltura = window.innerHeight;
    //             if (rect.top < ventanaAltura) {
    //                 setVisible(true);
    //             }
    //         }
    //     }
    // }

    // useEffect(()=>{
    //     window.addEventListener('scroll',activacionScroll);
    //     return(window.removeEventListener('scroll',activacionScroll))
    // },[]);

    //ref sections
    const sectionRefUno = useRef(null);
    const contacto = useRef(null);
    //Scroll a las Section
    const scrollSections = (ref)=>()=>{
        if (ref.current) {
            ref.current.scrollIntoView({behavior:'smooth'});
        }
    }
    return(
        <div className="paginaDos">

        <Nav
        titulo='Empsites'
        a1='Inicio' enlace1 ='../'
        // clickUno= {scrollSections(sectionRefUno)}
        a2='Nosotros' enlace2 = './'
        clickDos={scrollSections(sectionRefUno)}
        a3='Contacto' 
        clickTres= {scrollSections(contacto)}
        colorFondo='rgb(116 77 249)'
        />

        <Section
        titulo='Sobre Nosotros' colorLetra='#fff' filtroLetra='drop-shadow(2px 4px 3px black)'
        tituloAnimacion= {'desplasamiento ease 1s'}
        imgH = {EmpresaImg}
        filtroImgH = 'blur(4px)'
        boton='Ver mas' click={scrollSections(sectionRefUno)}
        
        />

        <section style={{padding:'100px 0px 100px 0px'}} ref={sectionRefUno}>
        {/* seguir trabajando en la funcion del scroll */}
        <SectionDos
        //  ref = {elementoRef}
        titulo='Somos Programadores'
        parrafo='Empesamos a principios año con el objetivo de hacer Sitios Webs eficientes que te ayuden a conseguir los objetivos que tengas en mente'
        fondoColor = "#fff"
        imgC = {ProgramadorImg} imgAltC='Crecimiento digital web'
        div = {true}
        colorLetra = "black"
        // buttonOpen = {true} boton= 'Ver mas' link = 'https://www.youtube.com'
        // animacion = "rotar infinite 20s linear"
        filter = "none"
        />
        </section>
        <SectionDos
        titulo='Como trabajamos?'
        // parrafo='Asi no tienes que hacerte problemas'
        fondoColor='rgb(116 77 249)'
        colorLetra = '#fff'
        buttonOpen={false}
        filtro = "drop-shadow"
        />
        <section style={{padding:'10px 0px 10px 0px'}}>
        <SectionDos
        titulo = 'Sobre React.js'
        parrafo = 'Trabajamos con la libreria de JavaScript llamada React en el lado del cliente que nos permite aumentar la productividad y bajar los costos!'
        fondoColor='#fff'
        imgC={ReactImg} imgAltC='react logo imagen'
        div = {true}
        colorLetra = "black"
        divPosition = {1}
        />
        </section>

        <section style={{padding:'10px 0px 10px 0px'}}>
        <SectionDos
        titulo = 'Sobre Node.js'
        parrafo = 'Tambien estamos incorporando el entorno de ejecucion de JavaScript Node.js para hacer la programacion en la parte de los servidores en los Sitios'
        fondoColor='#fff'
        imgC={NodeImg} imgAltC='node logo imagen'
        div = {true}
        colorLetra = "black"
        divPosition = {2}
        />
        </section>
        <section ref={contacto}>
        <Contacto
        h3= 'Contactanos!'
        p1 = 'Si quieres que realizemos tu Sitio puedes contactarnos por estos medios'
        colorLetra= '#fff'
        fondoColor = 'rgb(116 77 249)'
        img1={LogoWeb} alt1='logo de empresa de desarrollo web'
        justifyContentIconos = 'space-evenly'
        instagramLink= 'https://www.instagram.com/empsitesweb/'
        gmailLink='mailto:empsitesweb@gmail.com'
        />
        </section>
        </div>
    );
}

export default PaginaDos;