import React,{useState,useEffect,useRef} from 'react'
import '../style/SectionDos.css'

function SectionDos (props){
    // const [visible,setVisible]=useState(false);
    // const elementoRef = useRef(null)

    // const scrollElement = ()=>{
    //     if(elementoRef.current){
    //         const rect = elementoRef.current.getBoundingClientRect();
    //         if (rect.top < window.innerHeight) {
    //             setVisible(true);
    //         };
    //     };
    // };

    // useEffect(()=>{
    //     window.addEventListener('scroll', scrollElement);
    //     return()=>{
    //         window.removeEventListener('scroll', scrollElement);
    //     };
    // },[]);


    // const [estadoUno, setEstadoUno] = useState(false);
    const displayDivs = (t)=>t === true? "flex" : "none";

    const divPosition = (t) =>{
        let positionUno = "position_uno";
        let positionDos = "position_dos";
        let nada = "";
        if (t === 1) {return positionUno}
        else if (t===2) {return positionDos} 
        else {return nada}
    } ;
    
    return(
        <section className="section__dos" style={{backgroundColor: props.fondoColor,
            
        }}>
            <div className="section__dos-absolute">
                <img src={props.imgH} alt={props.imgAltH} className='img__fondo'/>
                    <div className="section__dos-absolute-img">
                        <img src={props.imgA} alt={props.imgAltA} />
                    </div>
                    <div className="section__dos-absolute-img">
                        <img src={props.imgB} alt={props.imgAltB} />
                    </div>
            </div>

            <div className={`section__dos_relative ${divPosition(props.divPosition)}`}  >
                <div className='section__dos_relative-div div__relative-uno' >
                    <div>
                        <h2 style={{color: props.colorLetra,
                                    filter : `${props.filtro}(2px 4px 2px black)`
                        }}>{props.titulo}</h2>
                        <p style={{color: props.colorLetra,
                                    filter: `${props.filtro}(2px 4px 2px black)`
                        }}>{props.parrafo}</p>
                        <a
                        style={{display : displayDivs(props.buttonOpen),
                                color: props.colorLetra,
                                border:`2px solid ${props.colorLetra}`
                                
                        }}
                        onClick={props.click}
                        href= {props.link}
                        >{props.boton}</a>
                    </div>
                    
                </div>
                <div className='section__dos_relative-div div__relative-dos'
                style={{display: displayDivs(props.div)}}
                >
                    <div>
                        <img src={props.imgC} alt={props.imgAltC} style={{
                            animation: `${props.animacion}`}}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionDos;