
import './App.css';
import React, {useState, useCallback, useRef} from 'react';
import Inicio from './Inicio'
import PaginaDos from './PaginaDos'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {
  const sectionRefUno = useRef(null);
  const sectionRefDos = useRef(null);
  const sectionRefTres = useRef(null);

  const scrollSections = (ref)=>()=>{
    if (ref.current) {
      ref.current.scrollIntoView({behavior:'smooth'});
    }
  }

  return (
    <div className="App">
    <Router>
      <Routes>
        <Route path="/" element={<Inicio />} />
        <Route path="/PaginaDos" element={<PaginaDos />} />
      </Routes>
    </Router>

    </div>
  );
}

export default App;
