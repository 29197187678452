import './ComponenteItem.css'

export function ComponenteItem(props) {
    

    return(
        <section className='componenteItem_section'>
                <div className='componenteItem_sectionAbsolute'>
                    
                </div>
                <div className='componenteItem_sectionRelative'>

                    <ItemUno
                    tituloItem = {props.tituloItemA}
                    parrafoItem = {props.parrafoItemA}
                    enlaceItem = {props.enlaceItemA} enlaceItemTexto = {props.enlaceItemTextoA}
                    imagenItemUno = {props.imagenItemUnoA} altItemUno = {props.altItemUnoA}
                    colorBorde = {props.colorBorde}
                    colorFondo = {props.colorFondoItem}
                    colorLetraEnlace = {props.colorLetraEnlace}
                    click={props.clickUno}
                    />

                    <ItemUno
                    tituloItem = {props.tituloItemB}
                    parrafoItem = {props.parrafoItemB}
                    enlaceItem = {props.enlaceItemB} enlaceItemTexto = {props.enlaceItemTextoB}
                    imagenItemUno = {props.imagenItemUnoB} altItemUno = {props.altItemUnoB}
                    colorBorde = {props.colorBorde}
                    colorFondo = {props.colorFondoItem}
                    colorLetraEnlace = {props.colorLetraEnlace}
                    click={props.clickDos}
                    />

<ItemUno
                    tituloItem = {props.tituloItemC}
                    parrafoItem = {props.parrafoItemC}
                    enlaceItem = {props.enlaceItemC} enlaceItemTexto = {props.enlaceItemTextoC}
                    imagenItemUno = {props.imagenItemUnoC} altItemUno = {props.altItemUnoC}
                    colorBorde = {props.colorBorde}
                    colorFondo = {props.colorFondoItem}
                    colorLetraEnlace = {props.colorLetraEnlace}
                    click={props.clickTres}
                    />

                </div>
        </section>
    );
}

export function ItemUno(props) {
    
    return(       
            <div className='componenteItem_sectionRelative_divItem' style={{border:`4px solid ${props.colorBorde}`}}>
                <div className='componenteItem_sectionRelative_divItem_Uno'>
                    <img src={props.imagenItemUno} alt={props.altItemUno} />
                </div>
                <div className='componenteItem_sectionRelative_divItem_Dos'>
                    <h2>{props.tituloItem}</h2>
                    <p>{props.parrafoItem}</p>
                    <a href={props.enlaceItem} onClick={props.click} style={{border:`2px solid ${props.colorBorde}`,
                                                        backgroundColor: props.colorFondo,
                                                        color: props.colorLetraEnlace
                }}>{props.enlaceItemTexto}</a>
                </div>              
            </div>
    );
}

export function Items() {
    return(
        <div></div>
    );
}

export default Items;