import { useState } from "react";
import './inicioUnos.css'

function InicioUno(props){

    return(
        <section className="inicio__uno">
            <div className="inicio__uno_div_absolute">
                <img className="inicio__uno_div_absolute_imgFondo" src={props.ImagenFondo} alt={props.AltImagenFondo} />
                <div className="inicio__uno_div_absolute_parteUno" style={{backgroundColor:props.ColorAbsoluteItem}}>

                </div>
                <div>

                </div>
            </div>

            <div className="inicio__uno_div_relative">
                <div className="inicio__uno_div_relative_parteUno">
                    <div className="inicio__uno_div_relative_parteUno__centro">
                        <h1 style={{color: props.ColorLetra}}
                        >   {props.Titulo}</h1>
                        <p style={{color: props.ColorLetra}}
                        >   {props.Descripcion}</p>
                        <a href="#" style={{color: props.ColorLetra}} onClick={props.clickUno}
                        >   {props.Enlace}</a>
                    </div>
                </div>
                <div className="inicio__uno_div_relative_parteDos">

                </div>
            </div>
        </section>
    );
}

export default InicioUno;